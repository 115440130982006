.funds {
  &-ad {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(395px, 1fr));
    column-gap: 15px;
    row-gap: 15px;
    margin-top: 36px;
    @media screen and (max-width: 767px) {
      grid-template-columns: auto;
    }
    &-box {
      display: grid;
      position: relative;
      background-color: var(--color-background-l0);
      box-shadow: 0 1px 3px var(--color-shadow), 0 1px 2px var(--color-shadow);
      overflow: hidden;
      cursor: pointer;
      &:hover {
        box-shadow: 0 3px 6px var(--color-shadow), 0 3px 6px var(--color-shadow);
      }
      border-radius: 6px;
      row-gap: 6px;
      grid-template-columns: minmax(70px, max-content) auto;
      grid-template-rows: max-content auto;
      @media screen and (max-width: 767px) {
        grid-template-rows: minmax(70px, max-content) max-content auto;
        grid-template-columns: auto;
      }
      &-title {
        display: grid;
        grid-template-columns: max-content auto;
        column-gap: 12px;
        align-items: center;
        grid-column: 2 / 3;
        padding: 20px 20px 0;
        @media screen and (max-width: 767px) {
          justify-content: space-between;
          grid-column: 1 / 3;
          grid-row: 2;
          padding: 4px 16px 0;
        }
        &-label {
          font-size: 16px;
          font-weight: 800;
          color: var(--color-text-l1);
          line-height: 1.71;
          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 1.3;
          }
        }
        &-arrow {
          display: flex;
          flex-shrink: 0;
          justify-content: flex-end;
          align-items: center;
          svg {
            height: 18px;
            width: 18px;
            @media screen and (max-width: 767px) {
              height: 14px;
              width: 14px;
            }
          }
        }
      }
      &-description {
        color: var(--color-text-l2);
        font-size: 14px;
        line-height: 1.83;
        grid-column: 2 / 3;
        grid-row: 2;
        padding: 0 20px 20px;
        @media screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 1.5;
          grid-row: 3 / 4;
          grid-column: 1 / 3;
          padding: 0 16px 16px;
        }
      }
      &-artwork {
        background-color: var(--color-background-hover);
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        display: grid;
        justify-content: center;
        align-items: center;
        padding: 10px;
        @media screen and (max-width: 767px) {
          grid-row: 1 / 2;
          grid-column: 1 / 3;
        }
      }
    }
  }
  &-p2p {
    background-color: var(--color-background-default);
    border-top: 1px solid var(--color-divider);
    border-right: 1px solid var(--color-divider);
    padding: 16px;
    margin-bottom: 16px;
    &.whitewash {
      background-color: var(--color-background-l0);
      box-shadow: 2px 2px 4px 0 var(--color-shadow);
      margin-bottom: 0;
    }
    @media screen and (min-width: 768px) {
      border: 1px solid var(--color-divider);
      .row {
        display: flex;
      }
    }
    &-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: 768px) {
        height: 100%;
      }
      &-group {
        width: 52px;
        height: 52px;
        margin: auto;
        position: relative;
      }
      &-box {
        background: var(--color-primary);
        height: 34px;
        border-radius: 50%;
        width: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border: 2px solid var(--color-divider);
        &.base {
          transform-origin: top right;
          position: absolute;
          transform: translate(50%, 0%);
        }
        &.quote {
          transform-origin: bottom left;
          position: absolute;
          transform: translate(0%, 50%);
        }
        img {
          height: 16px;
          width: 16px;
          filter: brightness(0) invert(1);
        }
      }
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0 16px;
      @media screen and (max-width: 767px) {
        align-items: center;
      }
      &-title {
        color: var(--color-text-l1);
        display: flex;
        @media screen and (max-width: 767px) {
          padding: 4px 0;
        }
        &-text {
          font-size: 18px;
          font-weight: 700;
        }
      }
      &-description {
        display: flex;
        @media screen and (max-width: 767px) {
          padding: 4px 0;
        }
        &-text {
          font-size: 12px;
          font-weight: 500;
          color: var(--color-text-l2);
          @media screen and (max-width: 767px) {
            text-align: center;
          }
        }
      }
    }
  }
  &-wrapper {
    padding: 48px 0 0;
    @media screen and (max-width: 767px) {
      padding: 32px 0 0;
    }
  }

  &-portfolio {
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.4px;
    color: var(--color-text-l2);
    font-weight: bold;

    @media screen and (max-width: 767px) {
      margin: 0 auto 10px;
    }

    @media screen and (min-width: 768px) {
      margin-left: auto;
      margin-bottom: -30px;
    }
  }
  &-tablist {
    display: grid;
    position: relative;
    grid-template-columns: auto max-content max-content;
    @media screen and (min-width: 768px) {
      justify-content: start;
    }
    @media screen and (max-width: 767px) {
      grid-template-rows: auto auto;
      grid-template-columns: auto auto;
    }
    &::before {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: var(--color-divider);
    }
    &-title {
      font-weight: 700;
      padding: 8px 0 16px;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 0.5px;
      color: var(--color-text-l0);
      @media screen and (max-width: 767px) {
        padding: 8px 0 4px;
        grid-row: 1;
        grid-column: 1 / 3;
        text-align: center;
      }
    }
    @mixin tablist-item {
      padding: 8px 16px;
      position: relative;
      text-transform: uppercase;
      color: var(--color-text-l2);
      font-size: 14px;
      letter-spacing: 0.5px;
      cursor: pointer;
      font-weight: 700;
      text-align: center;

      &.selected {
        color: var(--color-primary);
        &::after {
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          bottom: 0;
          left: 0;
          background-color: var(--color-primary);
        }
      }
    }
    &-item {
      @include tablist-item;
      &.selected {
        color: var(--color-primary);
        &::after {
          background-color: var(--color-primary);
        }
      }
    }
    &-deposit-box {
      @include tablist-item;
      &.selected {
        color: var(--color-green);
        &::after {
          background-color: var(--color-green);
        }
      }
    }
  }

  &-container {
    &-body {
      padding: 8px 0;
    }
  }
}

.funds-table-settings-popover {
  background-color: var(--color-background-l0);
  border: 0px;

  .popover-content {
    padding: 0px;
  }
}
.funds-table-settings-popover.bottom > .arrow {
  border-bottom-color: transparent;
  top: -8px;
  &:after {
    border-bottom-color: var(--color-background-l0);
    margin-left: 0;
  }
}
