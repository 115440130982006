$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';

@import 'variables';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import 'overrides';
@import 'material-fonts';
@import 'form';
@import 'dropzone';
@import 'helpers';
@import 'notification';
@import 'tooltip';
@import 'components/tradehistory';
@import 'components/orderHistory';
@import 'components/transactTable';
@import 'components/transactHistory';
@import 'components/funds';
@import 'animations/blink';
@import 'plugins/pagination';
@import 'plugins/toggle-button';
@import 'plugins/checkbox';
@import 'plugins/slick';
@import 'plugins/slick-theme';
@import 'plugins/telephone-input';
@import 'plugins/hypervergeModalOverrides';
@import 'components/contest';
@import 'components/listSort';
@import 'components/mine-wrx';
@import 'components/table';
@import 'components/bsBox';
@import 'components/matchingBox';
@import 'components/matchedBox';
@import 'components/buySellForm';
@import 'components/p2pOrders';
@import 'components/xid';
@import 'components/reactTagsInput';
@import 'components/paymentOptions';
@import 'components/dhamaka';
@import 'components/pressCoverage';
@import 'components/preferences';
@import 'components/partnerx';
@import 'components/fees';
@import 'components/faqs';
@import 'components/settings';
@import 'components/downloads';
@import 'components/xidOrderBook';
@import 'components/p2pOrderbook';
@import 'components/orderBookTable';
@import 'components/two-fa';
@import 'components/emailApproval';
@import 'components/activityLogTable';
@import 'components/featureChecklist';
@import 'components/yearlyReview';
@import 'components/ticker';
@import 'components/responsiveTabs';
@import 'components/orders';
@import 'components/responsivePopup';
@import 'components/footer';
@import 'components/unlockProgram';
@import 'components/oAuth';
@import 'components/userJourney';
@import 'components/binanceLanding';
@import 'components/minToggleGroup';
@import 'components/coupons';
@import 'plugins/datepicker';
@import 'components/tradingReport';
@import 'components/keyManager';

// themes
@import 'themes/light.scss';
@import 'themes/dark.scss';

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body,
#root {
  position: relative;

  // any undefined text color will fallback to default here
  color: var(--color-text-l1);

  // Done for https://wazirx.atlassian.net/browse/WAZ-7563
  @media only screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    inset: 0;
  }
}

//copy to clipboard
.copy-clipboard {
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--color-divider);
  border-radius: 4px;
  color: var(--color-primary);
  cursor: copy;
  transition: 0.4s all;
  font-weight: bold;
  &:hover {
    border: dashed 1px var(--color-divider);
  }
  .btn-aside,
  .input {
    display: flex;
    align-items: center;
  }
  .input {
    height: 48px;
    padding: 0 15px;
    flex-basis: 100%;
  }
  .btn-aside {
    height: 46px;
    justify-content: center;
    padding: 0 13px;
    font-size: 22px;
    background: var(--color-background-l1);
    margin-right: 1px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

.no-gutter {
  & {
    padding: 0;
  }
  &-left {
    padding-left: 0;
  }
  &-right {
    padding-right: 0;
  }
  &-xs {
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
}

.table > thead > tr > th {
  border-bottom: 1px solid var(--color-divider);
  padding: 4px 14px;
}
.table > tbody > tr > td {
  padding: 2px 14px;
  border: none;
}
.navbar-default .navbar-nav > .open > a {
  background: transparent !important;
}

.navbar-default .navbar-toggle {
  &:active {
    box-shadow: inset 1px 1px 1px 1px var(--color-divider);
  }
  &:focus {
    background-color: var(--color-primary);
  }
}
.popover > .arrow {
  border-width: 7px;
}

.popover.bottom > .arrow {
  margin-left: -7px;
  top: -7px;
}

.popover.bottom > .arrow:after {
  margin-left: -6px;
  border-bottom-color: var(--color-background-l0);
}
.popover > .arrow:after {
  border-width: 6px;
}

.popover.bottom {
  margin-top: 7px;
}

hr {
  border-color: var(--color-divider);
}

input {
  background-color: var(--color-background-input);
}

// For autofill, use the input color instead of chrome override color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  color: var(--color-text-l1) !important;
  -webkit-text-fill-color: var(--color-text-l1) !important;
  box-shadow: 0 0 0 30px var(--color-background-input) inset !important;
  -webkit-box-shadow: 0 0 0 30px var(--color-background-input) inset !important;
}
